/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@1.12.4/dist/jquery.min.js
 * - /npm/bootstrap@3.1.1/dist/js/bootstrap.min.js
 * - /npm/moment@2.22.2/min/moment-with-locales.min.js
 * - /npm/js-cookie@2.2.0/src/js.cookie.min.js
 * - /npm/hashids@1.1.4/dist/hashids.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
